<template>
  <component :is="template"></component>
</template>
<script>

import { mapGetters } from "vuex";
import tipo_fianzaList from "./tipo_fianzaList";

export default {
  components: {
    tipo_fianzaList,
  },
  data() {
    return {
      tipoFianza: {
        estado : true,
      },
    };
  },
  mounted() {
    let muta = {};

    if (this.$route.query.oper === undefined) {
      muta.oper = "list";
      muta.tipoFianza = this.tipoFianza;
    } else {
      muta.oper = this.$route.query.oper;
    }
    
    this.$store.commit("gsfianza/OPERACION_TIPO", muta);
  },

  computed: { ...mapGetters({ template: "gsfianza/getTipoFianzaTpl" }) },
};
</script>
<style>
</style>